import React from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'

const Title = ({title}) => <h1 className="section-headline">{title}</h1>
const EvertagLink = ({tag}) => <Link to={`/tag/${tag.id}`} title={tag.name}>{tag.name}</Link>
const TagTable = ({tags}) => <table>
	<tr>
		<th>posts</th>
		<th>tag</th>
	</tr>
	{tags.map(tag => <tr>
		<td>{tag.postCount}</td>
		<td><EvertagLink tag={tag}/></td>
	</tr>)}
</table>


class TagOverview extends React.Component {
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const page = {
			title: `Tags`
		}

		const tags = get(this, 'props.data.tags').edges
			.map(({node}) => node)
			.map(({id, name, everpost}) => ({
				id,
				name,
				postCount: everpost ? everpost.length : 0
			}))
			.filter(({postCount}) => postCount > 0)
			.sort((a, b) => b.postCount - a.postCount)

		const title = `${page.title} | ${siteTitle}`;
		return (
			<Layout location={this.props.location} title={title}>
				<div className="mainArticle">
					<div className="wrapper">
						<Title title={page.title}/>
						<TagTable tags={tags}/>
					</div>
				</div>
			</Layout>
		)
	}
}

export default TagOverview

export const pageQuery = graphql`
query TagOverviewQuery {
  tags: allContentfulEvertag {
    edges {
      node {
        id: contentful_id
        name
        everpost {
          id
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`
